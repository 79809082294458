.color_white_for_battery {
  color: #fff;
}

.batteryPercent{
  color: grey !important;
}



