.child-alert {
  max-width: 25%;
  text-align: center;
  top: 100px;
  left: 45%;
  padding-top: 1rem;

  .MuiDialogContent-root {
    overflow-y: hidden;
  }
}

.glowBoxShadow {
  box-shadow: 0 0 10px #9ecaed;
  /* border-radius: 10px; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
  margin-top: 8px;
  border-radius: 5px;
}

.responsiveShow {
  display: none;
}

@media only screen and (max-width: 800px) {
  .responsiveShow {
    display: block;
  }
}

.MuiPopover-paper {
  border-radius: 4px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.20) !important;
}
