.MuiLoadingButton-loadingIndicator{
    color: white !important;
}

.rectangle {
    height: 50px;
    background-color: #0e56a6;
    padding: 1rem;
    text-align: center;
    color: white;
}