.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.d-vertically-center {
  display: flex;
  align-items: center;
}

.d-vertically-hori-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDrawer-paper{
  -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
    overflow:inherit
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fe901a;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}