.cardSpace{
    padding-left: 1.5rem !important;
}

.textCenter{
    text-align: center;
}

.floatRight{
    float: right;
}

.bgRed{
    background-color: red;
}

.colRed{
    color: red;
}

.bgGreen{
    background-color: green;
}

.btn-switch {
    background-color: white;
    border: none;
    padding: 7px;
    border-radius: 7px;
    width: 90px;
    cursor: pointer;
    font-weight: 700;
  }
  .btn-switch-active {
    border: none;
    padding: 7px;
    border-radius: 7px;
    color: white;
    width: 90px;
    cursor: pointer;
  }

  @media screen and (max-width: 280px)  {
    .fenceName{
        margin-left: 1rem;
    }
    .fenseAddress{
        word-break: break-word;
    }

  }

  @media screen and (max-width: 912px) and  (min-width: 900px) {
    .fenceName{
        margin-left: 1rem;
    }
    .fenseAddress{
        word-break: break-word;
    }

  }