.linux-container{
  margin: auto;
  width: 65%;
  padding: 10px;
  h1{
    line-height: initial;
  }
  h2{
    line-height: initial;
  }
  .fontStyles{
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.6em;
    color: black;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .linux-container {
    width: 85%;
  }
}