.glowing-border {
  outline: none;
  border-color: #9ecaed;
  // box-shadow: 0 0 10px #9ecaed;
  border-radius: 10px;
  border: 3px solid #9ecaed;  
}

.cursor-pointer {
  cursor: pointer;
}

.shadowCardChild{
 
  margin-bottom: 1rem;
  box-shadow: 0 0 10px #9ecaed;
margin-right: 1rem;
}


