.imgPreview {
    width: 6rem;
    margin-bottom: 15px;
    border: 5px solid lightgray;
    height: 8rem;
  }
  .avatar-image-upload{
    display: flex;
    justify-content: center;
    // .MuiAvatar-img{
    //   object-fit: contain !important;
    // }
  }
  .child-addImage-btn{
    position: absolute;
    background-color: rgb(254, 144, 26);
    height: 42px;
    border-radius: 30px;
    width: 42px;
    justify-content: center;
    display: flex;
    align-items: center;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }

