.glowing-border {
  outline: none;
  border-color: #0e56a6;
  // box-shadow: 0 0 10px #0e56a6 !important;
  border-radius: 10px;
  background-color: #0e56a6;

  .MuiCardHeader-title{
    color: white;
  }
  .MuiCardHeader-subheader{
    color: white;
  }
  .MuiSvgIcon-fontSizeMedium{
    color: white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.shadowCardChild {
  margin-bottom: 1rem;
  // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  margin-right: 1rem;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #0e56a6;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #0e56a6;
  margin-left: 6rem;
}
