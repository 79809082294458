@media only screen and (max-width: 899px) {
    .logoutContainer{
        margin-top: -10rem;
    }

  }

  .boxShadowProp{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;

    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .logoutContainer:hover {
    background-color: white;
    color: #0e56a6 !important;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 10px;
    margin-top: 1.5rem;
  }

 

  .logoutContainer{
    color: white;
  }

  @media only screen and (max-width: 280px) {
    .mbleView{
       overflow-y: scroll;
    }
    .logoutContainer{
      margin-top: 0rem;
  }

  }

  .Mui-selected{
    background-color: white !important;
    color: #0e56a6 !important;
    
    .MuiTypography-h5{
      color: #0e56a6 !important;
    }
    .p{
      color: #0e56a6 !important;
    }
    .MuiTypography-body1{
      color: #0e56a6 !important;
    }
  }
  .MuiBadge-badge{
    background-color: red !important;
  }


  @media screen and (max-width: 915px) and (min-width: 900px) {
    .maintheme{
      margin-left: 1rem !important;
    }

  }